<template>
  <div class="header">
  	<div class="wrap">
  		<div class="logo">
  			<router-link to="/"><img src="@/assets/images/logo.png" alt="" /></router-link>
  		</div>
  		<div class="naver">
  			<ul>
  				<li :class="{ active: $route.path === '/' }"><router-link to="/" active-class="active-link">首页</router-link></li>
				  <li :class="{ active: $route.path === '/page2' }"><router-link to="/page2" active-class="active-link">AI学院专业规划</router-link></li>
				  <li :class="{ active: $route.path === '/page1' || $route.path === '/page3' || $route.path === '/page4' }">
				      <router-link to="/page1" active-class="active-link">AI职业规划</router-link>
				  </li>
				  <li :class="{ active: $route.path === '' }"><router-link to="/" active-class="active-link">薪资对比</router-link></li>
				  <li :class="{ active: $route.path === '' }"><router-link to="/" active-class="active-link">关于我们</router-link></li>
  			</ul>
  		</div>
  	</div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
