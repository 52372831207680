<template>
	<div class="layout">
		<div class="home">
			<Header />
			<div class="banner padtop">
				<div class="wrap">
					<div class="txt">
						<img src="@/assets/images/logo.png" alt="" />
						<h4>通过最新的人工智能系统和大数据为您提供<br>
							专业的学校及相关专业的建议，<br>
							以及职业规划和真实的薪资对比，<br>
							让您看未来不迷茫</h4>
						<div class="btn">
							开始测试 <img src='@/assets/images/ico1.png' alt='' />
						</div>
					</div>
					<div class="num">
						<ul>
							<li>
								<h3>20+</h3>
								<h5>学校</h5>
							</li>
							<li>
								<h3>100+</h3>
								<h5>专业</h5>
							</li>
							<li>
								<h3>100+</h3>
								<h5>职业</h5>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="hezuo">
				<div class="wrap">
					<div class="lt">
						<div class="tit">
							合作伙伴
						</div>
						<ul>
							<li><img src="@/assets/images/mlogo.png" alt="" /></li>
							<li><img src="@/assets/images/mlogo.png" alt="" /></li>
							<li><img src="@/assets/images/mlogo.png" alt="" /></li>
							<li><img src="@/assets/images/mlogo.png" alt="" /></li>
							<li><img src="@/assets/images/mlogo.png" alt="" /></li>
							<li><img src="@/assets/images/mlogo.png" alt="" /></li>
						</ul>
					</div>
					<div class="pic">
						<img src="@/assets/images/pic1.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	// @ is an alias to /src
	import Header from '@/components/Header.vue'

	export default {
		name: 'HomeView',
		components: {
			Header
		}
	}
</script>